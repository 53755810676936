.work-time-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: .625em;
  padding-bottom: .625em;

  &.pointer {
    cursor: pointer;
  }

  &:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .day-of-month {
    min-width: 46px;
    height: 46px;
  }

  .title {
    width: 100%;
    overflow: hidden;
  }

  .duration {
    font-weight: bold;
    line-height: 1;
  }
}
