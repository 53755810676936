@import 'src/assets/scss/bootstrap.scss';

.user-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: $primary;
  font-weight: 500;
  background-color: saturate(lighten($primary, 25), 20);
}
