.day-of-month {
  font-size: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  overflow: hidden;

  span {
    line-height: 1;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.day {
      font-size: 1.25em;
    }
  }
}
