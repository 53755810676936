:root {
  --primary-light: #{saturate(lighten($primary, 25), 20)};
}

html, body {
  color: $gray-900;
  background-color: $gray-100;
}

#root-app {
  display: unset;
}
