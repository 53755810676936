@import "src/assets/scss/bootstrap.scss";

.tight-layout {
  padding-top: 20px;
  padding-bottom: 20px;

  @include media-breakpoint-up(sm) {
    padding-top: 10vh;
  }
}
