.progress-bar-loader {
  display: flex;
  background-color: var(--primary-light);

  &, & .bar {
    height: 6px;
    width: 100%;
    margin: 0;
  }

  & .bar {
    animation: progress-animation 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    background-color: var(--primary);
  }
}

@keyframes progress-animation {
  0% { margin-left: 0px; margin-right: 100%; }
  50% { margin-left: 25%; margin-right: 0%; }
  100% { margin-left: 100%; margin-right: 0; }
}
