@import "src/assets/scss/bootstrap.scss";

.navigation-bar {
  height: 80px;

  .profile-toggle {
    cursor: pointer;

    &::after {
      display: none;
    }
  }

  .display-name {
    font-size: $font-size-base;
  }
}
